<template>
    <div>
       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Feedback List':'Lista de Comentarios'}}</b-card-title>
            </div>
            <div>
            <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
        </div>
        </b-card-header> 
        <b-table
         :busy="isBusy"
          hover
        striped
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        >
        <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
       </b-table>
   
         <b-col cols="12">
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
    </div>

</template>
<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge} from "bootstrap-vue"
import SellerService from '@/services/SellerService'
export default {
    components:{BCard,BCardHeader,BCardTitle,BCardBody,BTable,BSpinner,BAvatar,BModal,BCardText,BImg,BAlert,
    BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge},
    data(){
        return{
            isBusy:true,
              fields:[],
            items:[],
          perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
        }
    },
    mounted(){
        this.getFedbacks()
        this.totalRows = this.items.length
    },
    methods:{
         onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
        getFedbacks:async function(){
            const d=(await SellerService.getFeedbakcs(JSON.parse(localStorage.getItem("auth")).discordId)).data
           // console.log(d);
            this.items=d
            this.isBusy=false
        }
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>